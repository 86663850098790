
form {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: 400px;
}

h2 {
    text-align: center;
    color: #333;
}

label {
    margin-top: 10px;
    font-weight: bold;
    color: #555;
}
.editMain
/* textarea, */
input {
    padding: 12px;
    margin: 18px -1px 17px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 16px;
}

button {
    padding: 10px;
    font-size: 16px;
    background-color: #3a59d5;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 2px;
}

button:hover {
    background-color: #3a65e6;
}

/* Optional: Add a subtle transition effect for a smoother hover effect */
button {
    transition: background-color 0.3s ease;
}

