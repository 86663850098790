



.header-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.navbar {
  display: flex;
  align-items: center;
}

.nav-btn {
  text-decoration: none;
  padding: 11px 15px;
  margin-right: 10px;
  color: #333;
  border-radius: 12px;
  transition: background-color 0.3s ease;
  text-shadow: 2px 2px 3px rgb(116 110 216 / 50%);
}

.nav-btn:hover {
  background-color: #cddef2;
}

.logout-btn {
  text-decoration: none;
  padding: 10px 15px;
  margin-left: auto; 
  color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  width: 15%;
}

.logout-btn:hover {
  background-color: #3a65e6;
}