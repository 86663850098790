

.container {
  margin-top: 50px;
  text-align: center;
}

.hashtags-container {
  margin-top: 20px;
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.hashtags-container h2 {
  font-size: 24px;
  color: #333;
}

.hashtags-container ul {
  list-style: none;
  padding: 0;
  margin-top: 10px;
}

.hashtags-container li {
  cursor: pointer;
  margin-bottom: 5px;
  display: inline-block;
  background-color: #4caf50;
  color: #fff;
  padding: 8px 15px;
  border-radius: 20px;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

.hashtags-container li:hover {
  background-color: #45a049;
}

.edit-container {
  margin-top: 20px;
}

.edit-container h3 {
  font-size: 20px;
}

.edit-container input {
  padding: 10px;
  font-size: 16px;
  margin-right: 10px;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 52%;
}

.edit-container button {
  padding: 10px;
  font-size: 16px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.error-message {
  color: red;
  margin-top: 10px;
}


/* .update-button */

.inputbox button{
  width: 15%;
    padding: 9px;
    background-color: #3a59d5;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 32px;
}



.inputbox input[type="text"]{
  width: 48% ;
    padding: 10px;
    margin-bottom: 16px;
    border: 1px solid #ccc ;
    border-radius: 4px ;
}