

h2 {
  text-align: center;
  color: #000;
  padding: 6px;
  border-radius: 13px;
  
}

label {
  display: block;
  margin-bottom: 8px;
  color: #555;
}


.data input {
  width: 48%;
  padding: 10px; 
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}


.login-page button {
  width: 51%;
    padding: 9px;
    background-color: #3a59d5;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 62px;
}


button:hover {
  background-color: #3a65e6;
}


.data input{
  width: 88%;
  padding: 10px; 
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;

}


.login-page {
  position: relative; 
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-bottom: 38px; 
  overflow-x: hidden; 
  overflow-y: hidden;
}


.login-container {
  max-width: 300px;
  margin: auto;
  padding: 14px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 287px;
  margin-top: 37px;
  margin-bottom: 20px;
}

.image {
  width: 100vw; 
  position: absolute;
  bottom: 0;
  left: 50%; 
  transform: translateX(-50%);
  z-index: -1;
  
}
