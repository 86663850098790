.tweet-table {
    border-collapse: collapse;
    width: 100%;
    padding: 10px;
  }
  
  .tweet-table th, .tweet-table td {
    border: 1px solid #204f7d;
    padding: 8px;
    text-align: left;
  }
  
  .tweet-table th {
    background-color: #a7bbd8;
  }


  .page-button {
    padding: 10px;
    font-size: 16px;
    background-color: #3a59d6;
    color: #fff;
    border: none;
    border-radius: 11px;
    cursor: pointer;
    margin: 3px;
    width: 10%;
}


.tweet{
  text-align: center;
    color: #fff;
    background-color: #3a59d5;
    padding: 6px;
    border-radius: 5px;
}


.action {
  width: 62%;
  padding: 7px;
  background-color: #3a59d5;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}


.action-icon {
  cursor: pointer; 
  margin-right: 5px;
  font-size: 1.2rem; 
  color: #333;
}

.action-icon:hover {
  color: #666; /* Change the color of the icon on hover */
}



.loader {
  border: 4px solid #f3f3f3; 
  border-top: 4px solid #3498db; 
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin: 20px auto; 
}

.confirmation-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.confirmation-dialog p {
  margin-bottom: 10px;
}

.confirmation-dialog button {
  padding: 8px 20px;
  font-size: 16px;
  background-color: #3a59d6;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.confirmation-dialog button:last-child {
  margin-right: 0;
}

.confirmation-dialog button:hover {
  background-color: #204f7d;
}