
.excel-uploader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 76vh;
  background-color: #f4f4f4; 
  
}


.center-box {
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); 
}


.heading {
  margin-bottom: 20px;
  color: #3a59d5; 
}

.file-input {
  width: 92%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}


.upload-button {
  width: 43%;
  padding: 12px;
  background-color: #3a59d5;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.upload-button:hover {
  background-color: #3a65e6;
}


.success-msg,
.error-msg {
  padding: 12px;
  border-radius: 5px;
  margin-top: 15px;
  text-align: center;
}

.success-msg {
  background-color: #4caf50;
  color: #fff;
}

.error-msg {
  background-color: #f44336;
  color: #fff;
}
